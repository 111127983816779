// import axios from "axios";
// import env from "../config";
import axios from "../api/axios";
// const client = axios.create({
//   baseURL: env.HEALTH_CHECKS_ENDPOINT,
//   json: true,
// });

export default {
  async execute(method, resource, data, requestheader = {}) {
    return axios
      .healthChecksEndPoint({
        method,
        url: resource,
        data,
        headers: requestheader
      })
      .then(req => {
        return req.data;
      });
  },
  CustomerHealthChecksHardware(companyid, fromDate, toDate){
    return this.execute("get", `/Stats/DeploymentStatus?companyId=${companyid}&fromDate=${fromDate}&toDate=${toDate}`);
  },
  CustomerInstallHealthChecks(companyid){
    return this.execute("get", `/Stats/NRUStatus?companyId=${companyid}`);
  },
  getCustomerInstallHealthChecks(companyid, fromDate, toDate){
    return this.execute("get", `Stats/CertifiedInstall?companyId=${companyid}&fromDate=${fromDate}&toDate=${toDate}`);
  },
  getCustomerInstallHealthChecksDetail(companyid, fromDate, toDate){
    return this.execute("get", `Stats/CertifiedInstallDetail?companyId=${companyid}&fromDate=${fromDate}&toDate=${toDate}`);
  },
  DaywiseHealthChecks(companyid, fromDate=null, toDate=null){
    return this.execute("get", `/Stats/UnitsAddedByDay?companyId=${companyid}&fromDate=${fromDate}&toDate=${toDate}`)
  },
  WeekwiseHealthChecks(companyid, fromDate=null, toDate=null){
    return this.execute("get", `/Stats/InstallationsByWeek?companyId=${companyid}&fromDate=${fromDate}&toDate=${toDate}`)
  },
  //New Api for Dashboard Week and Day Charts

  UnitInstallationByDayWeek(companyid, fromDate = null, toDate = null) {
    return this.execute(
      "get",
      `/Stats/UnitInstallationByDayWeek?companyId=${companyid}&fromDate=${fromDate}&toDate=${toDate}`
    );
  },

  getCompanyNamesList(){
    return this.execute("get", `/Stats/CompanyList`);
  },
  getCompanies(){
    return this.execute("get", `/client/BasicDetails`);
  },
  installDaywiseHealthChecks(companyid, fromDate=null, toDate=null){
    return this.execute("get", `/Stats/InstallationsByDay?companyId=${companyid}&fromDate=${fromDate}&toDate=${toDate}`)
  },
  getNRUStatusdata(companyid){
    return this.execute("get", `/Stats/NRUStatus/Detail?companyId=${companyid}`);
  },
  checkRequied(fromDate, toDate,headerpayload){
    return this.execute("get", `/InstallException/CheckRequired?fromDate=${fromDate}&toDate=${toDate}`,null,headerpayload);
  },
  CreateCheckReq(payload,headerpayload){
    return this.execute("post", `/InstallException/CertifyInstalls`, payload,headerpayload);
  },
  getTUReports(customerReferenceId){
    return this.execute("get", `/Client/${customerReferenceId}/reports`);
  },
  getUnitsBydayExcel(companyId){
    return this.execute("get", `/Stats/UnitsAddedByDay/Download?companyId=${companyId}`);
  },
  getInstallsBydayExcel(companyId){
    return this.execute("get", `/Stats/InstallationsByDay/Download?companyId=${companyId}`);
  },
  getInstallsByWeekExcel(companyId){
    return this.execute("get", `/Stats/InstallationsByWeek/Download?companyId=${companyId}`);
  },
}