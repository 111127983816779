import pageheader from "../../common/pageheader";
//import apiService from "../../../api/api.service";
import healthChecks from "../../../api/healthChecks.service.js";
import appsheettoolsService from "../../../api/appsheettools.service";
import { mapGetters } from "vuex";
// import colors from "../../utils/app.json";
// import { hasAccess } from "../../config/admin.route.config";
import Vue from "vue";
import XLSX from "xlsx";
import JsonExcel from "vue-json-excel";
import utils from "../../../utils/utils";
import EventBus from "../../../utils/EventBus";
import companyapi from "@/api/company.service";
Vue.component("downloadExcel", JsonExcel);
export default {
  name: "dashboard",
  components: { pageheader, XLSX },
  props: [],
  data() {
    return {
      input: {},
      isHealthChecksWeekReports: false,
      isSWeekDataAvailable: false,
      noHealthChecksWeekReports: false,
      isHealthChecksInstallDayReports: false,
      isInstallDayDataAvailable: false,
      noHealthChecksDayReports: false,
      InstallationsByWeekDetailes:[],
      InstallationsByWeekDetailsTitleData:[],
      InstallationsGraphtitledata: [],
      WeekExcel: [],
      DayExcel: [],
      TitleOfWeek: "",
      TitleOfDay: "",
      NameOfWeek: "",
      NameOfDay: "",
      ExcelName: "",
      WeekNumbers: [],
      weekSeries: [
        // {
        //   data: []
        // }
        {
          type: "column",
          data: []
        },
        {
          type: "line",
          data: []
        }
      ],
      fromDate: "",
      toDate: "",
      InstallationsExcel: [],
      DayWiseList: [],
      installDaySeriesCategories: [],
      installDaySeries: [
        {
          type: "column",
          data: []
        },
        {
          type: "line",
          data: []
        }
      ],
      CRId:"",
      TitleOfInstallsDay: "",
      NameOfInstallsDay: "",
      exportingExcel: false,
      selectedExportOption: null,
      exportOptions: [
        "INSTALLATIONS BY WEEK",
        "INSTALLATIONS BY DAY"
      ],
      noDataMessage: "No data available to download excel",
      parentcompaniesList: [],
      companySelected: {},
      loading: false,
      oemsList: [],
      storedCRIds: [],
    };
  },
  computed: {
    ...mapGetters(["reportId","roles", "referenceId","customerReferenceId","companyName","toolIds","reportId","loggedInReferenceId"]),
    weekChartOptions: function () {
      return {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0]
        },
        legend: {
          show: false
        },
        stroke: {
          curve: "straight"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
          title: {
            text: 'Weeks',
            style: {
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Segoe UI, Roboto, sans-serif',
            },
          },
          categories: this.WeekNumbers
        },
        yaxis: {
          showAlways: true,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            }
          },
          title: {
            text: 'Installations Count',
            style: {
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Segoe UI, Roboto, sans-serif',
            },
          },
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: [0, 2]
        }
      };
    },
    installDayChartOptions: function () {
      return {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },

        dataLabels: {
          enabled: true,
          enabledOnSeries: [0]
        },
        legend: {
          show: false
        },
        stroke: {
          curve: "straight"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
          title: {
            text: 'Days',
            style: {
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Segoe UI, Roboto, sans-serif',
            },
          },
          categories: this.installDaySeriesCategories
        },
        yaxis: {
          showAlways: true,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            }
          },
          title: {
            text: 'Installations Count',
            style: {
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Segoe UI, Roboto, sans-serif',
            },
          },
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: [0, 2]
        }
      };
    },
  },

  mounted() {
    this.CompanyList();
    // this.ExcelName = this.$store.getters.companyName;
    // getting input content
    this.input = utils.getInputContent("dashboard", this.roles);
    //   if(this.reportId){
    //   this.getUnitInstallationByDayWeekData();
    // }
    EventBus.$on('call-dashboard', () => {
      this.getUnitInstallationByDayWeekData();
    });
  },
  beforeDestroy() {
    EventBus.$off("call-dashboard");
   },
  methods: {
    async CompanyList() {
      let checkLiteTool = false;
      this.oemsList = [];
      this.ExcelName = "";
      if(this.toolIds.includes('B4ADFA53-83C3-46FF-A158-F1CBDBB0E97A')){
        checkLiteTool = true;
      }else{
        checkLiteTool = false;
      }
       try {
         this.loading = true;
         let response = await companyapi.getAllCustomers();
         if (response) {
          this.oemsList = response.data.filter(
            (e) => e.isOEMCompany == true
          );
          let oem = this.oemsList.filter(
            (c) => c.id == this.$store.getters.oemCompanyId
          );
          this.ExcelName = oem ? oem[0] ? oem[0].name : "" : "";
           this.parentcompaniesList = response.data;
          
           if (this.roles == "User" && checkLiteTool) {
             // storedCRIds
              //Role is user & have unit360 tool
             this.storedCRIds = this.loggedInReferenceId.split(",");
             var newValues = [];
             for (var k = 0; k < this.storedCRIds.length; k++) {
               newValues.push({
                 index: k,
                 referenceId: this.storedCRIds[k],
               });
             }
             let tempdata = [];
             for (var i = 0; i < this.parentcompaniesList.length; i++) {
               for (var j = 0; j < newValues.length; j++) {
                 if (this.parentcompaniesList[i].id == newValues[j].referenceId) {
                   tempdata.push(this.parentcompaniesList[i]);
                 }
               }
             }
             this.parentcompaniesList = tempdata;
           }
           else if(this.roles == "User" && !checkLiteTool){
              //Role is user & not have unit360 tool
             this.parentcompaniesList = response.data.filter(
               (c) => this.$store.getters.oemCRIds.includes(c.id)
             );
           }
           if(this.roles == 'Manager' && checkLiteTool){
               //Role is manager & have unit360 tool
               // displaying all customers related to selected contractor ... based on ids provided in customerIds in login response
              this.parentcompaniesList = response.data.filter(
               (c) => this.$store.getters.customerIds.includes(c.id)
             );
           }
           else if(this.roles == 'Manager' && !checkLiteTool){
             //Role is manager & not have unit360 tool
               this.parentcompaniesList = response.data;
           }
           //set first item in dropdown in case if user doesnot have default customer
             this.companySelected.id = this.parentcompaniesList[0].id;
             this.companySelected.name = this.parentcompaniesList[0].name;
             this.getUnitInstallationByDayWeekData();
          }
          // this.getUnitInstallationByDayWeekData();
         this.loading = false;
       
       } catch (error) {
         this.loading = false;
         //this.$toast.error("Unable to load the data" + error);
       }
     },
     updateCompany(){
      this.getUnitInstallationByDayWeekData();
     },
     removeCustomer(){
      this.companySelected = {
        id: '',
        name: ''
      };
     },
    async getUnitInstallationByDayWeekData() {
      // this.CRId = this.$store.getters.reportId;
      this.CRId = this.companySelected ? this.companySelected.id : '';
      this.WeekExcel = [];
      this.TitleOfWeek = "";
      this.NameOfWeek = "";
      this.WeekNumbers = [];
      this.weekSeries = [
        // {
        //   data: []
        // }
        {
          type: "column",
          data: []
        },
        {
          type: "line",
          data: []
        }
      ];
      this.InstallationsExcel = [];
      this.TitleOfInstallsDay = "";
      this.NameOfInstallsDay = "";
      this.DayWiseList = [];
      this.installDaySeriesCategories = [];
      this.installDaySeries = [
        {
          type: "column",
          data: []
        },
        {
          type: "line",
          data: []
        }
      ];
      try {
        this.isHealthChecksInstallDayReports = true;
        this.isHealthChecksWeekReports = true;
        this.isHealthChecksDayReports = true;
        let response = await appsheettoolsService.UnitInstallationByDayWeek(
          this.CRId,
          this.fromDate,
          this.toDate,
          this.$store.getters.oemCompanyId
        );
        //handling INSTALLATIONS BY WEEK chart
        if (
          Array.isArray(response.installationsByWeek) &&
          response.installationsByWeek.length > 0
        ) {
          this.WeekExcel = [];
          this.WeekExcel = response.installationsByWeek;
          this.TitleOfWeek = "";
          this.NameOfWeek = "";

          this.TitleOfWeek = this.ExcelName + "_Installations_By_Week";
          this.NameOfWeek = this.ExcelName + "_Installations_By_Week";
          this.WeekNumbers = response.installationsByWeek.map(
            item => item.weekRange
          );

          this.weekSeries[0].name = "Installations";
          this.weekSeries[1].name = "Installations";
          this.weekSeries[0].data = response.installationsByWeek.map(
            item => item.count
          );
          this.weekSeries[1].data = response.installationsByWeek.map(
            item => item.count
          );
          this.isHealthChecksWeekReports = false;
          this.isSWeekDataAvailable = true;
        } else {
          this.isHealthChecksWeekReports = false;
          this.noHealthChecksWeekReports = true;
          this.isSWeekDataAvailable = false;
        }

        //handle INSTALLATIONS BY DAY chart
        if (
          Array.isArray(response.installationsByDay) &&
          response.installationsByDay.length > 0
        ) {
          this.InstallationsExcel = response.installationsByDay;
          this.TitleOfInstallsDay = "";
          this.NameOfInstallsDay = "";
          this.TitleOfInstallsDay = this.ExcelName + "_Installations_By_Day";
          this.NameOfInstallsDay = this.ExcelName + "_Installations_By_Day";
          this.DayWiseList = response.installationsByDay;
          this.installDaySeriesCategories = response.installationsByDay.map(
            item => item.dayName
          );
          this.installDaySeries[0].name = "Installations";
          this.installDaySeries[1].name = "Installations";
          this.installDaySeries[0].data = response.installationsByDay.map(
            item => item.count
          );
          this.installDaySeries[1].data = response.installationsByDay.map(
            item => item.count
          );
          this.isHealthChecksInstallDayReports = false;
          this.isInstallDayDataAvailable = true;
        } else {
          this.isHealthChecksInstallDayReports = false;
          this.noHealthChecksDayReports = true;
          this.isInstallDayDataAvailable = false;
        }
      } catch (error) {
        this.isHealthChecksInstallDayReports = false;
        this.isInstallDayDataAvailable = false;

        this.isHealthChecksWeekReports = false;
        this.isSWeekDataAvailable = false;

        this.isHealthChecksDayReports = false;
        this.isDayDataAvailable = false;
      }
    },
    async WeekWise() {
      // let Id = this.customerReferenceId;
    
      try {
        this.isHealthChecksWeekReports = true;
        let response = await healthChecks.WeekwiseHealthChecks(
          // Id,
          this.CRId,
          this.fromDate,
          this.toDate
        );
        console.log("Dashboard",response);
        if (Array.isArray(response) && response.length > 0) {
          this.WeekExcel = [];
          this.WeekExcel = response;
          this.TitleOfWeek = "";
          this.NameOfWeek = "";
          this.TitleOfWeek = this.ExcelName + "_Installatons_By_Week";
          this.NameOfWeek = this.ExcelName + "_Installatons_By_Week";
          this.WeekNumbers = response.map(item => item.weekRange);

          this.weekSeries[0].name = "Installed Units";
          this.weekSeries[0].data = response.map(item => item.count);
          this.isHealthChecksWeekReports = false;
          this.isSWeekDataAvailable = true;
        } else {
          this.isHealthChecksWeekReports = false;
          this.noHealthChecksWeekReports = true;
          this.isSWeekDataAvailable = false;
        }
      } catch (error) {
        this.isHealthChecksWeekReports = false;
        this.noHealthChecksWeekReports = true;
        this.isSWeekDataAvailable = false;
      }
    },
    async installDayWise() {
      // let Id = this.customerReferenceId;
      
      try {
        this.isHealthChecksInstallDayReports = true;
        let response = await healthChecks.installDaywiseHealthChecks(
          // Id,
          this.CRId,
          this.fromDate,
          this.toDate
        );
        if (Array.isArray(response) && response.length > 0) {
          this.DayExcel = [];
          this.DayExcel = response;
          this.InstallationsExcel = response;
          this.TitleOfDay = "";
          this.NameOfDay = "";
          this.TitleOfDay = this.ExcelName + "_Units_Added_By_Day";
          this.NameOfDay = this.ExcelName + "_Units_Added_By_Day";
          this.DayWiseList = response;
          this.installDaySeriesCategories = response.map(item => item.dayName);
          this.installDaySeries[0].name = "Installations";
          this.installDaySeries[1].name = "Installations";
          this.installDaySeries[0].data = response.map(item => item.count);
          this.installDaySeries[1].data = response.map(item => item.count);
          this.isHealthChecksInstallDayReports = false;
          this.isInstallDayDataAvailable = true;
        } else {
          this.isHealthChecksInstallDayReports = false;
          this.noHealthChecksDayReports = true;
          this.isInstallDayDataAvailable = false;
        }
      } catch (error) {
        this.isInstallDayDataAvailable = false;
        this.isHealthChecksInstallDayReports = false;
        //this.$toast.error("No Data Found" + error);
      }
    },
    async LoadInstallationsByDay() {
      this.InstallationsExcelData = [];
      try {
        let result = await appsheettoolsService.getInstallsBydayExcel(
          // this.customerReferenceId
          this.CRId,
          this.$store.getters.oemCompanyId
          );
        if (result) {
          this.InstallationsExcelData = result;
        }
      } catch (err) {
        this.InstallationsExcelData = [];
        this.$toast.error("Unable to load Installation Details");
      }
      this.InstallationsGraphtitledata = [];
      // this.ExcelName = this.name;
      this.startrow = 4;
      this.InstallationsGraphtitledata.push([
        this.ExcelName + "_Installations_By_Day"
      ]);
      this.InstallationsGraphtitledata.push([]);
      this.InstallationsGraphtitledata.push(["Day Name", "Count"]);
      let datarow = "A" + this.startrow;
      var ws = XLSX.utils.aoa_to_sheet(this.InstallationsGraphtitledata);
      XLSX.utils.sheet_add_json(ws, this.InstallationsExcel, {
        header: ["dayName", "count"],
        skipHeader: true,
        origin: datarow
      });
      // Installation Details
      this.installationDetails = [];
      this.startrow = 4;
      this.installationDetails.push([this.ExcelName + "_Installations_By_Day"]);
      this.installationDetails.push([]);
      this.installationDetails.push([
        "Serail Number",
        "Unit Name",
        "Installed Date",
        "Device Model",
        "Brand",
        "Model",
        "Vin",
        "Production Date"
      ]);
      datarow = "A" + this.startrow;
      var ws1 = XLSX.utils.aoa_to_sheet(this.installationDetails);
      XLSX.utils.sheet_add_json(ws1, this.InstallationsExcelData, {
        header: [
          "serialNumber",
          "unitName",
          "installedDate",
          "deviceModel",
          "brand",
          "model",
          "vin",
          "productionDate"
        ],
        skipHeader: true,
        origin: datarow
      });
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Installations_By_Day");
      XLSX.utils.book_append_sheet(wb, ws1, "Installations_Details");
      this.InstallationsGraphtitledata = [];
      this.installationDetails = [];
      XLSX.writeFile(wb, this.ExcelName + "_Installations_By_Day" + ".xlsx");
      this.selectedExportOption = "";
      this.exportingExcel = false;
    },
    async LoadInstallationsByWeek() {
      this.InstallationsByWeekDetailes = [];
      try {
        let result = await appsheettoolsService.getInstallsByWeekExcel(
          // this.customerReferenceId
          this.CRId,
          this.$store.getters.oemCompanyId
        );
        if (result) {
          this.InstallationsByWeekDetailes = result;
        }
      } catch (err) {
        this.InstallationsByWeekDetailes = [];
        this.$toast.error("Unable to load Installation Details");
      }
      // Week Graph details first sheet
      this.InstallationsByWeekGraphtitledata = [];
      this.startrow = 4;
      this.InstallationsByWeekGraphtitledata.push([
        this.ExcelName + "_Installations_By_Week"
      ]);
      this.InstallationsByWeekGraphtitledata.push([]);
      this.InstallationsByWeekGraphtitledata.push(["Week Range", "Count"]);
      let datarow = "A" + this.startrow;
      var ws = XLSX.utils.aoa_to_sheet(this.InstallationsByWeekGraphtitledata);
      let weekExcel = this.WeekExcel.map(function (item) {
        delete item.weekNumber;
        return item;
      });
      XLSX.utils.sheet_add_json(ws, weekExcel, {
        header: ["weekRange", "count"],
        skipHeader: true,
        origin: datarow
      });

      // week details second sheet
      this.InstallationsByWeekDetailsTitleData = [];
      this.startrow = 4;
      this.InstallationsByWeekDetailsTitleData.push([
        this.ExcelName + "_Installations_By_Week_Details"
      ]);
      this.InstallationsByWeekDetailsTitleData.push([]);
      this.InstallationsByWeekDetailsTitleData.push([
        "Serail Number",
        "Unit Name",
        "Installed Date",
        "Device Model",
        "Brand",
        "Model",
        "Vin",
        "Production Date"
      ]);
      datarow = "A" + this.startrow;
      var ws1 = XLSX.utils.aoa_to_sheet(
        this.InstallationsByWeekDetailsTitleData
      );
      // let weekExcelDetails = this.InstallationsByWeekDetailes;
      XLSX.utils.sheet_add_json(ws1, this.InstallationsByWeekDetailes, {
        header: [
          "serialNumber",
          "unitName",
          "installedDate",
          "deviceModel",
          "brand",
          "model",
          "vin",
          "productionDate"
        ],
        skipHeader: true,
        origin: datarow
      });
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Installations_By_Week");
      XLSX.utils.book_append_sheet(wb, ws1, "Installations_By_Week_Details");
      this.InstallationsByWeekGraphtitledata = [];
      this.InstallationsByWeekDetailsTitleData = [];
      XLSX.writeFile(wb, this.ExcelName + "_Installations_By_Week" + ".xlsx");
      this.selectedExportOption = "";
      this.exportingExcel = false;
    },
    exportIconClick() {
      if (this.selectedExportOption) {
        this.exportingExcel = true;
        //case  "INSTALLATIONS BY WEEK"
        if (this.selectedExportOption == this.exportOptions[0]) {
          if (this.isSWeekDataAvailable) {
            setTimeout(() => {
              this.LoadInstallationsByWeek();
            }, 3000);
          } else {
            setTimeout(() => {
              this.selectedExportOption = null;
              this.exportingExcel = "";
              this.$toast.error(this.noDataMessage);
            }, 1000);
            
          }
        }
        //case  "INSTALLATIONS BY DAY"
      else  if (this.selectedExportOption == this.exportOptions[1]) {
          if (this.isInstallDayDataAvailable) {
            setTimeout(() => {
              this.LoadInstallationsByDay();
            }, 3000);
          } else {
            setTimeout(() => {
              this.selectedExportOption = "";
              this.exportingExcel = false;
              this.$toast.error(this.noDataMessage);
            }, 1000);
          }
        }
      } else {
        this.$toast.error("Please select an export option");
      }
    }
  }
};
